            function ChkFinDate(ObjDt)
            {
                if(ObjDt.length <5)
                {
                    ObjDt.value = "";
                    return ;
                }
                var sDt = ObjDt.value;

                var sDD, sMM, sYY;
                var i;
                var sCh;
                var sLen;
                var sDD = "";
                var sMM = "";
                var sYY = "";
                var flag =1;
                sLen = sDt.length;
                var sDays = [31,28,31,30,31,30,31,31,30,31,30,31];
                var sCrtflag = 1;
                var j=1;
                var NewStr = "";

                for(i=0 ; i<sLen ; i++)
                {
                    
                    sCh = sDt.charAt(i);
                    if(sCh>=0 && sCh<= 9)
                        NewStr = NewStr+sCh;
                }

                sDt = NewStr
                sLen = sDt.length;

                for(i=0 ; i<sLen ; i++)
                {
                    sCh = sDt.charAt(i);
                    if(sCh>=0 && sCh<= 9)
                    {
                        if(flag == 1)
                            sDD = sDD + sCh;
                        if(flag == 2)
                            sMM = sMM + sCh;
                        if(flag == 3)
                            sYY = sYY + sCh;
                        
                        if(j>=2 && flag <=2)
                        {
                            j=1;
                            flag = flag + 1;
                        }
                        else
                            j=j+1;
                    }
                    else
                       flag = flag + 1;
                }
                if(sDD == "")
                {
                    ObjDt.value = "";
                    return;
                }
                if(sMM == "")
                    ObjDt.value = "";
                if(sYY == "")
                {
                    ObjDt.value = "";
                    return;
                }
                if(sDD.length ==1)
                    sDD = "0" + sDD;

                if(sMM.length ==1)
                    sMM = "0" + sMM;
                
                
                if(sYY.length <= 2)
                {
                if(parseInt(sYY) < 70)
                    sYY = 2000 + Math.round(sYY);
                else
                    sYY = 1900 + Math.round(sYY);
                }

                if((sYY%4 == 0 && sYY%100 != 0) || (sYY%400 == 0 ))
                    sDays[1] = 29;
                else
                    sDays[1] = 28;
                    
                if(sMM > 12 )
                {
                    sCrtflag = 0;
                    //alert("1. Invalid Date....");
                    ObjDt.value = "";
                    //ObjDt.focus();
                }
                if(sDD > sDays[sMM-1])
                {
                    sCrtflag = 0;
                    //alert("Invalid Date");
                    ObjDt.value = "";
                    //ObjDt.focus();
                }
                if (sCrtflag == 1) 
                {
                    if(sYY+sMM+sDD>20200401)
                    {
                        //alert("Date After Range -> " + sYY+sMM+sDD );
                        ObjDt.value = "";
                        // ObjDt.focus();
                    }
                    if (sYY+sMM+sDD<20140401) 
                    {
                        //alert("Date Before Range -> " + sYY+sMM+sDD );
                        ObjDt.value = "";
                        // ObjDt.focus();
                    }
                    else 
                    {
                        ObjDt.value = sDD + "/" + sMM + "/" + sYY;
                    }
                }
            }

            function ChkMyDate(ObjDt)
            {
                if(ObjDt.length <5)
                {
                    ObjDt.value = "";
                    return ;
                }
                var sDt = ObjDt.value;


                var sDD, sMM, sYY;
                var i;
                var sCh;
                var sLen;
                var sDD = "";
                var sMM = "";
                var sYY = "";
                var flag =1;
                sLen = sDt.length;
                var sDays = [31,28,31,30,31,30,31,31,30,31,30,31];
                var sCrtflag = 1;
                for(i=0 ; i<sLen ; i++)
                {
                    sCh = sDt.charAt(i);
                    if(sCh>=0 && sCh<= 9)
                    {
                        if(flag == 1)
                            sDD = sDD + sCh;
                        if(flag == 2)
                            sMM = sMM + sCh;
                        if(flag == 3)
                            sYY = sYY + sCh;
                    }
                    else
                       flag = flag + 1;
                }

                if(sDD == "")
                {
                    ObjDt.value = "";
                    return;
                }
                if(sMM == "")
                    ObjDt.value = "";
                if(sYY == "")
                {
                    ObjDt.value = "";
                    return;
                }
                if(sDD.length ==1)
                    sDD = "0" + sDD;

                if(sMM.length ==1)
                    sMM = "0" + sMM;
                
                
                if(sYY.length <= 2)
                {
                if(parseInt(sYY) < 50)
                    sYY = 2000 + Math.round(sYY);
                else
                    sYY = 1900 + Math.round(sYY);
                }

                if((sYY%4 == 0 && sYY%100 != 0) || (sYY%400 == 0 ))
                    sDays[1] = 29;
                else
                    sDays[1] = 28;
                    
                if(sMM > 12 )
                {
                    sCrtflag = 0;
                    alert("4. Invalid Date....");
                    ObjDt.value = "";
                    ObjDt.focus();
                }
                if(sDD > sDays[sMM-1])
                {
                    sCrtflag = 0;
                    alert("5 Invalid Date");
                    ObjDt.value = "";
                    ObjDt.focus();
                }
                if(sCrtflag == 1)
                {
                    ObjDt.value = sDD + "/" + sMM + "/" + sYY;
                }
            }

            function ShowObj(sObj)
            {
                sObj.style.visibility  = "visible";
            }
            function getVal(sObjFrom, sObjTo)
            {
                var sInd = 0;
                var sCtFrom;
                sInd = sObjFrom.selectedIndex;
                if (sInd >= 0 )
                    sCtFrom = sObjFrom.options[sInd].text;
                sObjTo.value = sCtFrom;
                sObjFrom.style.visibility  = "hidden";
            }

            function getOnlyVal(sObjFrom, sObjTo) {
                var sInd = 0;
                var sCtFrom;
                sInd = sObjFrom.selectedIndex;
                if (sInd >= 0 )
                    sCtFrom = sObjFrom.options[sInd].text;
                sObjTo.value = sCtFrom;


            }

            function gNumOnly(ObjNum)
            {

                if(ObjNum.length <1)
                {
                    ObjNum.value = "";
                    return;
                }
                var sNum = ObjNum.value;
                var i;
                var sCh;
                var sLen;
                sLen = sNum.length;

                for(i=0 ; i<sLen ; i++)
                {
                    sCh = sNum.charAt(i);
                    if(sCh>=0 && sCh<= 9)
                    {}
                    else
                    {
                       ObjNum.value = "";
                       return;
                    }
                }
                    
            }


            function gNumDot(ObjNum)
            {

                if(ObjNum.length <1)
                {
                    ObjNum.value = "";
                    return;
                }
                var sNum = ObjNum.value;
                var i;
                var sCh;
                var sLen;
                sLen = sNum.length;

                for(i=0 ; i<sLen ; i++)
                {
                    sCh = sNum.charAt(i);
                    if((sCh>=0 && sCh<= 9) || sCh=='.')
                    {}
                    else
                    {
                       ObjNum.value = "";
                       return;
                    }
                }
                    
            }
    function IsNumericVal(sNo)
    {
        if(sNo >= 48 && sNo <= 57)
            return true;
        else
            return false;
    }
    
    function CrctVhNo(sObj)
    {

        var i;
        var j;
        var sVhNo1 = "";
        var flag = 0;
        var sCh;
        var sNo = 0;
        var sNo1 = 0;
        var sNo0 = 0;
        var sLen = 0;
        var sBlank = "";
        
        sVhNo = sObj;
        
        if (sVhNo == "")
        {
            sObj = "";
            return "" ;
        }
        
        i = 0;
        sLen = sVhNo.length;
        
        while (i < sLen)
        {
            if(sVhNo.charAt(i) != ' ')
            {
                sNo = (sVhNo.charAt(i)).charCodeAt(0);
                
                if(sNo >= 97 && sNo <= 122)
                {
                    sNo = sNo - 32;
                    sCh = String.fromCharCode(sNo);
                    sVhNo1 = sVhNo1 + sCh;
                }
                else
                    sVhNo1 = sVhNo1 + sVhNo.charAt(i);
            }
            i = i + 1;
        }

        
        sVhNo = sVhNo1;
        sVhNo1 = "";
        i = 0;
        flag = 1;
        sLen = sVhNo.length;
        
//      Remove all extra character like - If No is GJ23-2545 then It will keep this - other wise remove all -       
        while (i < sLen)
        {
            sCh = sVhNo.charAt(i);
            sNo = (sVhNo.charAt(i)).charCodeAt(0);
            if(i < sLen-1)
                sNo1 =(sVhNo.charAt(i+1)).charCodeAt(0);
            else
                sNo1 = 0;
            
            if(i > 0)
                sNo0 =(sVhNo.charAt(i-1)).charCodeAt(0);
            else
                sNo0 = 0;
            
            if((sNo >= 65 && sNo <= 90) || (sNo >= 48 && sNo <= 57) )
            {
                sVhNo1 = sVhNo1 + sCh;
                i = i + 1;
            }   
            else
            {
                if(i > 1)
                {
                    if(IsNumericVal(sNo0) == true && sCh == '-' && IsNumericVal(sNo1) && flag == 1)
                    {
                        sVhNo1 = sVhNo1 + sCh;
                        i = i + 1;
                        flag = 0;
                    }
                    else
                    {
                        i = i + 1;
                    }
                }
                else
                {
                    i = i + 1;
                }
            }
        }
        
        sLen = sVhNo.length;

        sVhNo = sVhNo1;
        i = 0;
        
        sNo =(sVhNo.charAt(i)).charCodeAt(0);
        sNo1 =(sVhNo.charAt(i+1)).charCodeAt(0);
       
        if(sNo < 65 || sNo > 90)
        {
            sObj.value = "";
            return "" ;
        }           
            
        if (sNo1 < 65 || sNo1 > 90) 
        {
            sObj.value = "";
            return "" ;
        }

        sNo = (sVhNo.charAt(2)).charCodeAt(0);
        
        var sPart1 = "";
        var sPart2 = "";
        var sPart3 = "";
        var sPart4 = "";


        if (sNo >= 65 && sNo <= 90 )
        {
            sPart1 = "";
            sPart2 = "";
            sPart3 = "";
            sPart4 = "";

            i = 0;
            j = 0;
            sNo =(sVhNo.charAt(i+j)).charCodeAt(0);
    
            while (sNo >= 65 && sNo <= 90)
            {
                sPart1 = sPart1 + sVhNo.charAt(i+j);
                j = j + 1;
                
                if(i + j > sLen )
                {
                    sObj.value = "";
                    return "" ;
                }
                sNo =(sVhNo.charAt(i+j)).charCodeAt(0);
            }
            
            if (j > 3) 
            {
                sObj.value = "";            
                return "" ;
            }

            i = j + i;
            j = 0;

            if(i + j > sLen ) 
            {
                sObj.value = "";            
                return "" ;
            }
            
            sNo =(sVhNo.charAt(i+j)).charCodeAt(0);

            while (sNo >= 48 && sNo <= 57 && (i + j) < sLen )
            {
                sPart4 = sPart4 + sVhNo.charAt(i+j);
                j = j + 1;

                if(i + j >= sLen)
                    break;

                sNo =(sVhNo.charAt(i+j)).charCodeAt(0);
            }

            if (j >= 5) 
            {
                sObj.value = "";
                return "" ;
            }
            if (sPart4 == "")
            {
                sObj.value = "";            
                return "" ;
            }
            if (i+j > sLen)
            {
                sObj.value = "";            
                return "" ;
            }
            sVhNo = sPart1 + "  -  " + sPart4;
            sObj.value = sVhNo;
            
            return "" ;
            
        }
//------------------------------------------------------------------------- Old Number logic is completed..
        

//        New Vehicle Number Logic now starts

        sNo =(sVhNo.charAt(2)).charCodeAt(0);
        if (sNo >= 48 && sNo <= 57)
        {
            sPart1 = "";
            sPart2 = "" ;
            sPart3 = "";
            sPart4 = "";

            i = 0;
            j = 0;
            sNo =(sVhNo.charAt(i+j)).charCodeAt(0);
            while (sNo >= 65 && sNo <= 90)
            {
                sPart1 = sPart1 + sVhNo.charAt(j);
                j = j + 1;
                if (i + j >= sLen)
                {
//                    Exit Do
                }
                sNo =(sVhNo.charAt(i+j)).charCodeAt(0);
            }
            if (j >= 3 )    
            {
                sObj.value = "";            
                return "" ;
            }
            
            i = 2;
            j = 0;
            sNo = (sVhNo.charAt(i+j)).charCodeAt(0);
            while (sNo >= 48 && sNo <= 57 ) 
            {
                sPart2 = sPart2 + sVhNo.charAt(i+j);
                j = j + 1;
                if (i + j >= sLen) 
                {
                    break;
                }
                sNo = (sVhNo.charAt(i+j)).charCodeAt(0);
            }

            if (j >= 3) 
            {
                sObj.value = "";            
                return "" ;
            }
            if(sPart2.length == 1)
                sPart2 = "0" + sPart2;

            i = j + i;
            j = 0;
            if (i >= sLen) 
            {
                sObj.value = "";            
                return "" ;
            }
                
            if (sVhNo.charAt(i) != '-')
            {
                sNo =(sVhNo.charAt(i+j)).charCodeAt(0);
                while (sNo >= 65 && sNo <= 90)
                {
                    sPart3 = sPart3 + sVhNo.charAt(i+j);
                    j = j + 1;
                    
                    if (i + j >= sLen) 
                    {
                        sNo = 0;
                        break;
                    }
                    sNo =(sVhNo.charAt(i+j)).charCodeAt(0);
                }
                
                
                if (j >= 3) 
                {
                    sObj.value = "";            
                    return "" ;
                }
                if (sPart3.length == 1)
                    sPart3 = " " + sPart3;
            }
            else
            {
                i = i + 1;
            }
            i = i + j;
            j = 0;

           if (i >= sLen) 
           {
                sObj.value = "";            
                return "" ;
           }
            sNo =(sVhNo.charAt(i+j)).charCodeAt(0);
            while (sNo >= 48 && sNo <= 57 && (i + j) <= sLen)
            {
                sCh = sVhNo.charAt(i+j);
                sPart4 = sPart4 + sVhNo.charAt(i+j);
                j = j + 1;
                if (i + j >= sLen) 
                {
                    sNo = 0;
                    break;
                }
                sNo =(sVhNo.charAt(i+j)).charCodeAt(0);
            }
            

            if (sPart4 == "" || j == 5 )
            {
                sObj.value = "";            
                return "" ;
            }
            i = i + j;
            j = 0;

            if (sPart3.length == 0) 
                sVhNo = sPart1 + "-" + sPart2 + " " + "- " + sPart4;
             else
                sVhNo = sPart1 + sPart2 + "-" + sPart3 + "-" + sPart4;
        }
        
        sObj.value = sVhNo;
        
        return sVhNo ;                
    }
    
    function AmountInWord(amount) {
        var words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';

        amount = amount.toString();
        var atemp = amount.split(".");
        var number = atemp[0].split(",").join("");
        var n_length = number.length;
        var words_string = "";
        if (n_length <= 9) {
            var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
            var received_n_array = new Array();
            for (var i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
                n_array[i] = received_n_array[j];
            }
            for (var i = 0, j = 1; i < 9; i++, j++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j]);
                        n_array[i] = 0;
                    }
                }
            }
            value = "";
            for (var i = 0; i < 9; i++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Crores ";
                }
                if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Lakhs ";
                }
                if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Thousand ";
                }
                if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                    words_string += "Hundred and ";
                } else if (i == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");

            if(atemp.length>1)
            {
                value = atemp[1];
                if(value > 0)
                {
                    if(value < 20) words_string = words_string + " and " + words[value] + " Paisa";
                    else
                    {
                        val1 = value.substr(0,1);
                        val2 = value.substr(1,1);
                        val1 = val1 * 10;
                        words_string = words_string + " and " + words[val1] + " " + words[val2] + " Paisa";
                    }
                }
            }
        }
        return words_string;
    }



